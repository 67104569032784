
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif; 
}

body {
  background-color: #f4f6f8;
  height: 100%;
}

a {
  text-decoration: none;
}

button:focus {
  outline:0;
}

#root {
  height: 100%;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px  #91bfe3; 
 /* border-radius: 10px; */
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #91bfe3; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #91bfe3; 
}